'use client';

import React from "react";
import Loading from "@/components/Loading";

export default function MainLoading() {
    return (
        <Loading />
    );
}
